.ag-cell{
    // height: 40px !important;
    border: 1px solid $theme-border-color !important;
    &:last-child{
        border-right:none !important;
    }
    &:first-child{
        border-left:none !important;
    }
}

.ag-row{
    border-bottom:  none !important;
}
.ag-theme-alpine{
    --ag-header-background-color:$white-color !important;
    --ag-odd-row-background-color:#EEF1F7 !important;
    --ag-border-color: #DBDEE5 !important;
}

app-credit-history{
    app-ag-grid{
        ag-grid-angular{
            .my-header-class {
                &:nth-child(4) , &:last-child{
                    text-align:end !important ;
                    .ag-header-cell-label{
                        justify-content: end !important;
                    }
                }
                
            }
        }
    }
}

.ag-header-cell-text{
    color: $label-text-color !important;
    font-size: $font-14 !important;
    font-weight: 500 !important;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
}

.status-header{
    .ag-header-cell-label{
        justify-content: center;
    }
}

.action-header{
    .ag-header-cell-label{
        justify-content: end;
    }
}
// .ag-header-cell {
//     height: 40px !important;
// }

.ag-cell-value {
    font-size: $font-12 !important;
}

.amount-header{
    .ag-header-cell-label{
        justify-content:  end !important;
    }
}
.date-header{
    .ag-header-cell-label{
        justify-content:  start !important;
    } 
}

#transaction_history
{
  app-ag-grid{
      ag-grid-angular{
          height: calc(100vh - 340px) !important;
          }
      }
}