
ngx-datatable, .info 
{
    text-align: left;
    width: 100%;
    margin: 0 auto;
    .header-text
    {
        color: $label-text-color !important;
        font-size: $font-14 !important;;
        position: relative;
        top: 5px;
        left: 10px;

        font-family: $theme-font-family-SourceSansPro-Semibold !important;
    }
    .headr-txt-left
    {
        text-align: end;
    }
    .type-cutomer{
        display: flex;
        justify-content: end;  
        left: -13px !important;
    }
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: #EEF1F7 !important;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    vertical-align: top;
    border-bottom: 2px solid $theme-border-color !important;
    border-left: 2px solid $theme-border-color !important;
    border-right: 2px solid $theme-border-color !important;
}
.datatable-header{
    height: 35px !important;
    border:1px solid $theme-border-color !important;
    width: calc(100% - 1px) !important;
}

.datatable-body-row{
    height: 32px !important;
}
.datatable-body-cell-label{
    position: relative;
    top: 8px;
}
//  .datatable-body-cell:not(:last-child)  {
//     border-right:2px solid $theme-border-color
// }
.datatable-header-inner
 {
  
    width: 100% !important;
  }

  .bage-position{
    position: relative;
    left: 20rem;
  }
  .datatable-body-cell:last-child .datatable-body-cell-label{
    float: right;
    position: relative;
    left: -2rem;
  }
  .datatable-body-cell{
    height: 32px !important;
    border-right: 1px solid $theme-border-color;
    &:last-child{
        border-right:none;
    }
  }

  .datatable-footer-inner{
    justify-content: space-between;
    border: 1px solid $theme-yellow-color;
    border-radius: 5px;
  }
  .pagination{
    --bs-pagination-border-width: 0px !important;
    // --bs-pagination-border-color:$white-color;
    --bs-pagination-font-size:12px !important;
    --bs-pagination-padding-x: .4rem;
    --bs-pagination-padding-y: .1rem;
    --bs-pagination-border-color: none !important;
    margin: 2px 4px;
    gap: 3px;
    align-items: center!important;
  }
  .disabled > .page-link 
  {
    background-color: transparent !important;
    color: black !important;

}

.grid-pagination > .pagination > li.page-item > a[aria-label="Last"] ::after  {
  margin-left:-14px;
  content: url("../../SVGicon/right-double-arrow.svg");      
  background-color: $white-color;
  position: absolute !important;
  right: 0px !important;
  top: 4px !important;
   padding: 0px 12px 0px 6px !important;
}  

.grid-pagination > .pagination > li.page-item > a[aria-label="First"] ::after  {
   content: url("../../SVGicon/left-double-arrow.svg");  
   padding: 0px 0px 0px 12px !important;
  position: absolute !important;
  right: 2px !important;
  top: 4px !important;
  background-color: $white-color;
}  



.disabled 
{
    opacity: 1 !important;
}
    .page-link 
    {
      border-radius: 50%!important;
    color: #455472;
    box-shadow: none!important;
    outline: none!important;
    font-size: 11px;

    }
    // .page-link:last-child{
    //   margin: 0px 2px !important ;
    // }
    .page-link:focus 
    {
      background-color: $theme-yellow-color ;
      border-color: $theme-yellow-color !important;
      border-radius: 50%;
      outline-color:$theme-yellow-color  !important;
      box-shadow: none !important;
      color: $white-color;
    }
    .active > .page-link 
    {
      background-color: $theme-yellow-color !important;
      border-color: $theme-yellow-color !important;
      border-radius: 50%;
    }
  
    .page-item
    {
      a[aria-label="First"]
      {
        margin: 0px 0px !important;
      }
    }
 .page-link[aria-label="First"],.page-link[aria-label="Last"],.page-link[aria-label="Previous"],.page-link[aria-label="Next"]
 {
  &:focus
  {
    outline:none!important;
    background-color: transparent!important;
  }
  }
  .datatable-footer-inner{
    height: 30px !important;
  }
  .form-select {
    --bs-form-select-bg-img: url("../../SVGicon/down-arrow.svg") !important;
    color: $label-text-color !important ;
    font-size: 12px;
    background-size: 11px 11px !important;

  }
  .datatable-footer{
    margin-top: 1.5rem !important;
  }

  .ngx-datatable.bootstrap .datatable-body {
    overflow: hidden auto !important;
    flex: 1 1 auto;
    margin: 0px 0px;
    margin-bottom: 5px;
    // min-height: 300px !important;
    width: 100% !important;
    height: calc(100vh - 583px) !important;
    // height: calc(100vh - 728px) !important;
}

.ngxdt-body{
  height: 100%;
    padding: 0.95rem 0 0 0;
  // margin-top: -17px;
  > * {
    letter-spacing: initial;
  }
}

// agrid csss
