.table{
  
    th{
        color: $label-text-color !important;
        font-size: $font-14 !important;
        border-top: 1px solid $theme-border-color;
        border-bottom:1px solid $theme-border-color !important;
        padding: 5px 8px !important;
        font-family: $theme-font-family-SourceSansPro-Semibold !important;
        &:first-child{
            border-left: 1px solid $theme-border-color ;
        }
        &:last-child{
            border-right: 1px solid $theme-border-color ;
        }
    }
    td{
        border:1px solid $theme-border-color ;
        padding: 5px 8px !important;
       
    }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    // --bs-table-color-type: var(--bs-table-striped-color);
    --bs-table-bg-type:#EEF1F7 ;
}
.isDeletes{
    
        border:2px solid #dc3545!important
    
    
}