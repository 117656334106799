.theme-primary-text
{
   color:  $color-primary !important;
}
.theme-secondary-text
{
    color: $color-secondary !important;
}
.theme-cell-value-color{
    color: $cell-value-color !important;
}
.theme-bg-primary{
    background-color:$color-primary!important;
}
.badge-theme-primary
{
   
    background-color:$theme-blue-color;
}
.badge-theme-secondary
{

    background-color: $theme-pink-color;
}
.text-td
{
    color : $label-text-color !important;
} 
// font size
.f-9{
    font-size: $font-9 !important;
}
.f-10{
    font-size: $font-10;
}
.f-11
{
    font-size: $font-11;
}
.f-12{
    font-size: $font-12 !important;
}
.f-14
{
 font-size: $font-14 !important;
}

.f-w-semibold
{
    font-weight: 600 !important;
}
.f-13
{
    font-size: $font-13 !important;
}
.f-16
{
    font-size:$font-16 !important;
}
.f-18{
    font-size: $font-18 !important;
}
.f-20{
    font-size: $font-20 !important;
}
.f-22{
    font-size : $font-22 !important;
}
.f-28{
    font-size: $font-28 !important;
}
// font-family
.font-regular {
    font-family: $theme-font-family-SourceSansPro-Regular !important;
}

.font-semibold {
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
}

.font-bold {
    font-family: $theme-font-family-SourceSansPro-bold !important;
}
.card-bg-blue
{
    background-color:$theme-card-blue!important;
}
.card-bg-violet
{
    background-color:$theme-card-violet!important;
}
.card-bg-magenta
{
    background-color: $theme-card-magenta !important;
}


.input-lable{
    color: $label-text-color;
    font-size: $font-14;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
    position: relative;
    top: 3px;

}
.form-control{
    border :1px solid $input-br-color !important;
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 6%) !important;
    border-radius: $br-8 !important;
    padding: 0.3rem 0.5rem  0.3rem 0.5rem !important;
    font-size: $font-12 !important;
    color: $cell-value-color !important;
    // line-height: 0 !important;
    height: 29px !important;
}
.form-control:disabled {
    background-color:#CFD3DC !important;

}
.checkbox label::before 
{
    border: 2px solid #CFD3DC !important;
}
.bg-green{
background-color: $color-green;
}
.border-blue{
   border: 1px solid $border-blue; 
   border-radius: 6px;
}
.text-green{
    color: $color-green;
}
.br-delete-danger{
    border: 1px solid $delete-color;
    border-radius: 6px;
}
.delete-icon-color{
    color: $delete-color;
  }
  .text-red{
    color: $text-red;
  }
  
.bg-yellow{
   background-color: $color-yellow !important; 
}
.bg-orange{
    background-color:  $color-orange
}
.bg-purpal{
    background-color : $color-purpal !important;
}
.bg-grey{
    background-color: $label-text-color !important;
}

.text-white{
    color: #fff
}

.bg-theme-primary{
    background-color:$color-primary !important ;
}

.border-theme-primary{
   border:1px solid $color-primary !important ;
}

.br-top{
    border-top:1px solid $theme-border-color !important ;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    z-index: 2;
    right:1px;
    top: 9%;
    font-size: 25px;
    background-color: rgb(0, 255, 238);
    width: 30px;
    height:28px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  /* Style the input container */
  .date-input-container 
  {
    position: relative;
  }
  .date-input-container::before {
    content: url("../../SVGicon/calender-icon.svg");  
    position: absolute;
    z-index: 1;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 26px;
    cursor: pointer;
    background-color: #F3F5F8;
    text-align: center;
    display: flex;
    height:27px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    justify-content: center;
    align-items: center;
    width: 34px;
  }

  .customSelect{
	border:1px solid $input-br-color;
	background: #ffffff url("../../SVGicon/selecte-down.svg") no-repeat top right;
	width: 356px;
	font-size: 14px;
	color: $color-primary;
	height: 41px;
	border-radius: 9px;
	padding-left: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
    background-size: 44px 45px;
    font-size: 14px;
    font-weight: 600;
    &:focus{
        outline: none !important;
    }
}

.br-right{
    border-left: 1px solid $theme-border-color;
}

.br-lg-right{
    border-left: 1px solid $theme-border-color; 
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #7C89A4;
    letter-spacing: 0px
}
.form-control::-webkit-input-placeholder{
    color:    #7C89A4 !important;
    letter-spacing: 0px
}

.pl-8{
    padding-left: 8px;
}
.br-16 
{
  border-radius: 16px !important;
}
.br-14 
{
  border-radius: 14px !important;
}