@import "variables";

.header-wrap
{
    border: 2px solid $theme-header-border-color !important;
    background-color: $theme-header-bg-color !important;
    border-radius: 10px !important;
    // height: 70px;
    .left-header 
    {
        img {
            width: 40px !important;
            height: 40px !important;
            position: fixed;
            top: 30px;
        }
    }

    .nav-right{
        ul{
            li{
                svg{
                    stroke: $color-primary !important;
                    fill: $color-primary !important;
                    width: 23px !important;
                    height: 23px !important;

                }
            } 
        } 
    }
}
.flex-0{
    flex: 0;
}
.flex-1{
    flex: 1;
}
.flex-2{
    flex: 3;
}
.flex-3{
    flex: 3;
}
.aling-item-unset{
    align-items: unset !important;
}

.img-60{
    width: 50px !important;
    height: 34px !important; 
}

.box-layout {
    .page-wrapper{
        .page-header{
            max-width: calc(100% - 54px);
            position: fixed;
            margin-top: 9px;
            background-color: none;
            .header-wrapper {
                padding: 0px 10px;               
            }
        }
    }  
}