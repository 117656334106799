//color variables
$theme-header-bg-color:#F3F5F8;
$theme-header-border-color:#D5B66B;
$color-primary:#00979E;
$color-secondary:#7C89A4;
$theme-border-color:#CFD3DC;
$white-color:#FFFFFF;
$label-text-color:#455472;
$btn-border-color-1:#DBDEE5;
$btn-bg-blue:#0D7FCB;
$btn-border-color-2:#A8BFEC;
$input-br-color:#CFD3DC;
$theme-blue-color:#1398EF;
$theme-pink-color:#CB4E80;
$color-green:#1BC068;
$color-yellow:#F8AE01;
$color-orange:#FF6763;
$color-purpal:#708EFF;
$theme-yellow-color:#D5B66B;
$border-blue:#0D7FCB;
$theme-border-color:#DBDEE5;
$delete-color:#FF6763;
$text-red:#FF6763;
$cell-value-color:#455472;
$theme-text-light-blue:#6AA1FF;
$theme-card-blue:#3e85ff;
$theme-card-violet :#8255ff;
$theme-card-magenta : #fc2f7c;
// font size
$font-9:9px;
$font-10:10px;
$font-11:11px;
$font-12:12px;
$font-13:13px;
$font-14:14px;
$font-16:16px;
$font-18:18px;
$font-17:17px;
$font-22:22px;
$font-28:28px;
$font-20 :20px;
//border-radius
$br-4:4px;
$br-8:8px;
$br-14:14px;
$br-13:13px;
$br-16:16px;

// font family
$theme-font-family-SourceSansPro-Regular: SourceSansPro-Regular;
$theme-font-family-SourceSansPro-Semibold: SourceSansPro-Semibold;
$theme-font-family-SourceSansPro-bold: SourceSansPro-bold;
