/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "./assets/scss/custom/variables";
@font-face {
    font-family: "SourceSansPro-Regular";
    src: local("SourceSansPro-Regular"),
        url(./assets/theme-font-family/SourceSansPro-Regular.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-Semibold";
    src: local("SourceSansPro-Semibold"),
        url(./assets/theme-font-family/SourceSansPro-Semibold.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-bold";
    src: local("SourceSansPro-bold"),
        url(./assets/theme-font-family/SourceSansPro-Bold.otf) format("truetype");
}

.component-wrapper {
    // padding: 20px;
    background: #fff;
    // height:calc(100vh - 10px) ;
    display: flex;
    flex-direction: column;
  }
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

body{
    //  font-family: 'Source Sans 3', sans-serif;
    background-color: #F3F5F8 !important; 
    padding: 2px 20px !important;
    font-family: $theme-font-family-SourceSansPro-Regular , sans-serif !important;

}
html{
    // font-family: 'Source Sans 3', sans-serif;
    font-family: $theme-font-family-SourceSansPro-Regular ,sans-serif !important;


}

@import "./assets/scss/custom/header";
@import "./assets/scss/custom/theme";
@import "./assets/scss/custom/variables";
@import "./assets/scss/custom/sidebar";
@import "./assets/scss/custom/nav-tab";
@import "./assets/scss/custom/button";
@import "./assets/scss/custom/table";
@import "./assets/scss/custom/ngx-datatable";
@import "./assets/scss/custom/ag-grid";


.profile-dropdown {
    left: -39px !important;
}

.align-item-center {
    align-items: center !important;
}

.pointer{
    cursor: pointer !important;
}

app-feather-icons{
    svg{
        height: 20px !important;
        width: 30px !important;
    }
}

app-credit-history{
        .ngx-datatable.bootstrap .datatable-body{
            height: calc(100vh - 382px) !important
        }
        ag-grid-angular{
            height: calc(100vh - 339px) !important;
            }
    }
    app-search-history {
        ag-grid-angular{
            height: calc(100vh - 299px) !important;
            }
        
    }

    // app-risk-assessment{
    //     app-ag-grid{
    //     ag-grid-angular{
    //         height: calc(100vh - 579px) !important;
    //         }
    //     }

       
    // }
    // app-risk-item-grid{
    //     ag-grid-angular{
    //         height: 140px !important;
    //         }
       
    // }
    app-user , app-screening , app-risk-assessment{
        ag-grid-angular{
            height: calc(100vh - 303px) !important;
            }
        .ngx-datatable.bootstrap .datatable-body {
            height: calc(100vh - 384px) !important;
        }
    }

    .form-control-error{
        color: #dc3545;
    }
    
    .error-message{
        color: #dc3545;
    }
    .has-error{
        .form-control{
            border-color: #dc3545!important;
        }
       
    }
    app-riskassement-individuals{
                .ngx-datatable.bootstrap .datatable-body
                {
                    height: 6rem !important;
                    overflow-y:  auto !important;
                }
    }

    app-riskassement-corporate{
                .ngx-datatable.bootstrap .datatable-body
                {
                    height: 6rem !important;
                    overflow-y:  auto !important;
                }
            
        
    }
    app-riskassement-corporate , app-riskassement-individuals{
    .ng-select-container{
        border :1px solid $input-br-color !important;
        box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 6%) !important;
        border-radius: $br-8 !important;
        min-height: 28px !important;
        height: 28px !important;
        font-size: 12px !important;
    }


}

app-customers{
    .ng-select-container{
        border :1px solid $input-br-color !important;
        box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 6%) !important;
        min-height: 30px !important;
        height: 30px !important;
        font-size: 12px !important;
    }
}

.border-red{
    border: 1px solid red !important;
}
.ng-error-border{
    
        .ng-select-container{
            border-color:  red !important;
        }
    

}
.border-blue:hover{
    border-color:#0D7FCB;
    background-color: #ccced0;
  }

  .fa-sort-down:before, .fa-sort-desc:before {
    top: -1px !important;
    position: relative !important;
    left: 14px;
}
.pe-29{
    padding-right: 29px !important;
}
.heading-position{
    position: relative;
    top: 3px;

}
.mr-67{
    margin-right: 66px !important;
  }



  .expandable-grid 
{
    .ag-cell{
      --ag-line-height: 30px !important;
    }
}

#add_screening
{
.ag-cell-value, .ag-group-value 
{
    overflow: visible !important;
}
}

.borders-0{
    border: 0px !important;
}
.border-0{
    border: 0px !important;
}
.cels{
    position: relative !important;
    top: -1px !important;
}

