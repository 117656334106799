.nav-tabs {
    border-radius: 8px;
    margin-bottom: 0 !important;
    border: 1px solid $color-primary;
    background-color: $white-color;
    column-gap: 0rem;
    justify-content: space-between;
    width: 37%;
    max-width: 180px;
    min-width: fit-content;
    .nav-link {
      padding: 4px 16px !important;
      // padding: 0.4rem 2.1rem !important;
    }
  
    a.nav-link {
      border-radius: 6px;
      // font-weight: 600;
      // padding: 10px 30px;
      font-family: $theme-font-family-SourceSansPro-Semibold!important;
      border: none;
      margin-bottom: 0;
      color: $color-primary;
      font-size:$font-14 !important;
      letter-spacing: 0px !important;
      &.active {
        color: $white-color;
        background-color: $color-primary;
      }
  
      &:hover {
        background-color: $color-primary;
        color: $white-color;
      }
    }
  }