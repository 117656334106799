.sidebar-wrap{
    .simplebar-content > li  
    {
        a{
            color: $color-secondary !important;
            font-size: $font-16 !important;
            padding: 5px 5px 5px 5px !important;
            line-height: 30px !important;
            margin: 0 20px 0px 0px !important;
            border-radius: 0px !important; 
            letter-spacing: 0.3px !important;
            // width: 100%;
        }
    }
}
.sidebar-wrap{
    .simplebar-content > li  

    {
        padding-top: 2px !important;
        padding-bottom: 0px !important; 
        a.active{
           background-color: transparent !important;
           border-bottom: 2px solid $color-primary !important;
           font-family: $theme-font-family-SourceSansPro-Semibold !important;
        }
        a.active span{
            color:  $color-primary !important;
        }

    }
}
.sidebar-wrap{
    .simplebar-content > li:hover  
    {
        a > span{
            color: $color-primary !important;
        }
       
    }
}
.sidebar-wrapper 
.sidebar-main 
.sidebar-links 
{
    border-bottom:  1px solid $theme-border-color !important;
}