.button
{
 border-radius: 7px;
 outline: none;
 padding: 3px 14px;
 width: auto;
 font-size: $font-14;
 letter-spacing: 0.1px;
}


.button-secondary
{
    border: 1px solid  $btn-border-color-1;
    color: $color-secondary;
    background-color: $white-color;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
}
.button-primary
{
    border: 1px solid $btn-border-color-2;
    color:$white-color;
    background-color: $btn-bg-blue;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
}
 .btn-green
 {
    
    color:$white-color;    
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
 }

 
.button-add 
{
    border: 1px solid $color-primary;
    color:$color-primary;
    background-color: $white-color;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
    padding: 2px 10px 3px 10px !important;
}


.company-btn
{
    border-radius: 4px;
    outline: none;
    padding: 1px 12px 3px 13px;
    font-size: 12px ;
    background-color: $theme-pink-color;
    color: $white-color;
    width: 30px;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
    
}
.person-btn{
    border-radius: 4px;
    outline: none;
    padding: 1px 19px 3px 19px;
    // width: 30px;
    font-size: 12px ;
    background-color: $theme-blue-color;
    color: $white-color;
    font-family: $theme-font-family-SourceSansPro-Semibold !important;
}

.border-green{
    border: 1px solid $color-green;
}
.border-yellow{
    border: 1px solid $color-yellow;
}
.border-orange{
    border: 1px solid $color-orange;
}

.back-button{
    @extend .button;
    border: 1px solid $color-primary;
    color:$white-color;
    background-color: $color-primary;
    font-family: $theme-font-family-SourceSansPro-Semibold ;
}

.btn-position
{
    position: absolute !important;
    top: 4px;
    width: 300px;
    right: 0px;
} 